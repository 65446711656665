
body {
  padding-top: 50px;
}

header {
  .navbar-fixed-top {
    border-width: 0 0 3px;
  }
  .navbar-header {
    .icon-bar {
      height:3px;
    }
    .navbar-toggle:hover .icon-bar,
    .navbar-toggle:active .icon-bar {
      background-color: $olive;
    }
    a.navbar-brand {
      padding: 12px 0 8px 50px;
    }


  }
}

/* Small devices (tablets, 768px and up) */
@media screen and (max-width: $screen-xs-max) {
  header {
    .navbar-header {
      a.navbar-brand {
        padding: 12px 0 8px 20px;
      }
    }
  }
}

.btn i {
  margin-right: 2px;
}

footer {
  background: #243c4f;
  padding: 60px 0; }
footer p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6); }
footer .footer-title {
  position: relative;
  font-size: 18px;
  text-transform: uppercase;
  color: white; }
footer .footer-title:after {
  content: '';
  display: block;
  width: 60px;
  height: 1px;
  background: #3ac341;
  margin-top: 8px; }
footer .right-border {
  border-right: 1px solid rgba(255, 255, 255, 0.1); }
footer .left-border {
  border-left: 1px solid rgba(255, 255, 255, 0.1); }
@media (max-width: 991px) {
  footer .copyright {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    margin-top: 40px;
    text-align: center; }
  footer .right-border {
    border-right: none; } }
@media (max-width: 767px) {
  footer .right-border {
    border-right: none; }
  footer .left-border {
    border-left: none; }
  footer .navigation {
    margin-top: 40px;
    border-top: 1px solid rgba(255, 255, 255, 0.1); } }
footer .footer-about {
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 40px; }
footer .contact-info .single {
  margin: 15px 0; }
footer .contact-info .single i {
  display: block;
  float: left;
  font-size: 1.2em;
  margin-right: 10px;
  line-height: 22px; }
footer .contact-info .single p {
  margin: 0;
  padding: 0;
  display: block;
  overflow: hidden; }

footer .navigation li a {
  color: #eee;
  text-transform: uppercase;
  line-height: 30px;
  text-decoration: none; }
footer .navigation li a:hover {
  color: #3ac341; }
footer .copyright {
  padding-top: 40px; }




footer #email {
  color: #eee;
}
footer #email:hover {
  text-decoration: none;
  color: rgb(82, 173, 129);
}

footer .navigation nav > ul > li > a:hover,
footer .navigation nav > ul > li > a:focus {
  text-decoration: none;
  color: rgb(82, 173, 129);
}

footer .icon-stack a {
  text-decoration: none;
}
footer .icon-stack a:hover,
footer .icon-stack a:focus{
  text-decoration: none;
}

footer .icon-circle.icon-stack-base {
  color: #fefefe;
}
footer .icon-facebook,
footer .icon-twitter,
footer .icon-google-plus {
  color: #5CB78B;
}

footer .icon-facebook:hover {
  color: rgb( 59, 89, 152);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

footer .icon-twitter:hover {
  color: rgb( 0, 172, 237);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

footer .icon-google-plus:hover {
  color: rgb( 221, 75, 57);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
