.search-section {
  padding-top: 50px;
  padding-bottom: 100px;
  min-height: 100vh;
}


#search-spinner {
  min-height: 400px;
  padding-top: 50px;
}
.spinner-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding: 30px 0
}
.spinner-text {
  margin-top: 20px;
  font-size: 25px;
}


.nav-tabs.nav-justified > .active > a.blue-bg,
.nav-tabs.nav-justified > .active > a.blue-bg:hover,
.nav-tabs.nav-justified > .active > a.blue-bg:focus {
  background-color: rgba(66, 139, 202, 0.7);
  color: #fff;
}
.nav-tabs.nav-justified > li > a.blue-bg:hover,
.nav-tabs.nav-justified > li > a.blue-bg:focus {
  background-color: rgba(66, 139, 202, 0.4);
  color: #fff;
}

.nav-tabs.nav-justified > .active > a.green-bg,
.nav-tabs.nav-justified > .active > a.green-bg:hover,
.nav-tabs.nav-justified > .active > a.green-bg:focus {
  background-color: rgba(92, 184, 92, 0.7);
  color: #fff;
}
.nav-tabs.nav-justified > li > a.green-bg:hover,
.nav-tabs.nav-justified > li > a.green-bg:focus {
  background-color: rgba(92, 184, 92, 0.5);
  color: #fff;
}

.nav-tabs.nav-justified > .active > a.red-bg,
.nav-tabs.nav-justified > .active > a.red-bg:hover,
.nav-tabs.nav-justified > .active > a.red-bg:focus {
  background-color: rgba(217, 83, 79,0.7);
  color: #fff;
}

.nav-tabs.nav-justified > li > a.red-bg:hover,
.nav-tabs.nav-justified > li > a.red-bg:focus {
  background-color: rgba(217, 83, 79,0.5);
  color: #fff;
}

.nav-tabs > li > a {
  color: #333;
}

.tab-content.main-search-tabs {
  padding-top: 50px;
  padding-bottom: 50px;
  overflow: auto;
  background: #fafbfc;
}

.has-feedback.has-clear.has-empty-value .form-control-clear {
  display: none;
}
.has-feedback.has-clear .has-empty-area {
  display: block;
  height: 10px;
}

#search-error {
  margin-top: 100px;
}

.odds-category-row {
  padding-top: 20px;
  padding-bottom: 20px;
}
.results-category-row {
  padding-top: 20px;
  padding-bottom: 40px;
}

.search-buttons {
  margin-top: 10px;
  .search-form-btn {
    display:inline-block;
  }
  #reset-btn {
    margin-left: 20px;
  }
  @media (max-width:476px) {
    .search-form-btn {
      display:block;
    }
    #reset-btn {
      margin-top: 20px;
      margin-left: 0px;
    }
  }
}