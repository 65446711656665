//NAVY
$navy: #001f3f;
//BLUE
$blue: #3F729B;
//AQUA
$aqua: #4682B4;
//TEAL
$teal: #39CCCC;
//OLIVE
$olive: #3D9970;
//GREEN
$green: #5CB78B;
//LIME
$lime: #01FF70;
//YELLOW
$yellow: #FFDC00;
//ORANGE
$orange: #FF851B;
//RED
$red: #ee4d4d;
//MAROON
$maroon: #85144b;
//FUCHSIA
$fuchsia: #F012BE;
//PURPLE
$purple: #B10DC9;
//BLACK
$black: #111111;
//BLACK-ELEGANT
$black-elegant: #2E2E2E;
//GRAY
$gray: #AAAAAA;
// GRAY -ELEGANT
$gray-elegant: #2F4F4F;
//SILVER
$silver: #DDDDDD;
// DARK BLUE
$dark-blue: #2b2e48;
//WHITE
$white: #FFFFFF;
//WHITE-DARK
$white-dark: #FAFBFC;