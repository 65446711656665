#home {

  > section {
    padding-top: 80px;
    /*padding-bottom: 80px;*/
    min-height: 100vh;
  }

  #intro {
    background: linear-gradient(to bottom, #fff, #EAEBEC);
    background-size: cover;
    color: $black-elegant;

    .search-btn {
      margin-top: 40px;
      font-size: 20px;
      padding: 15px;
      text-transform: uppercase;
    }

    #intro-img {
      width: 50%;
      position: relative;
      bottom: 0;
    }

  }


  #features {
    background: $white-dark;

    .stylish-panel {
      padding: 20px 0;
      text-align: center;

      > div > div {
        padding: 10px;
      }

      .icon-stack > i {
        color: $olive;
      }

    }

  }

  #explanation {

    // Timeline
    $timeline-color: $red;

    // Mixins and Placeholders
    %clearfix {
      &:after, &:before {
        content: '';
        display: block;
        width: 100%;
        clear: both;
      }
    }

    @mixin prefix($prop, $val) {
      @each $prefix in '-webkit-', '-moz-', '-ms-', '' {
        #{$prefix}#{$prop}: $val;
      }
    }


    // Timeline
    #timeline {
      width: 100%;
      margin: 30px auto;
      position: relative;
      padding: 0 10px;
      @include prefix(transition, all .4s ease);

      &:before {
        content:"";
        width: 3px;
        height: 100%;
        background: $timeline-color;
        left: 50%;
        top: 0;
        position: absolute;
      }

      &:after {
        content: "";
        clear: both;
        display: table;
        width: 100%;
      }

      .timeline-item {
        margin-bottom: 50px;
        position: relative;
        @extend %clearfix;

        .timeline-icon {
          background: $timeline-color;
          width: 50px;
          height: 50px;
          position: absolute;
          top: 0;
          left: 50%;
          overflow: hidden;
          margin-left: -23px;
          @include prefix(border-radius, 50%);

          svg {
            position: relative;
            top: 14px;
            left: 14px;
          }
        }

        .timeline-content {
          width: 45%;
          background: $timeline-color;
          color: #fff;
          padding: 20px;
          @include prefix(box-shadow, 0 3px 0 rgba(0,0,0,0.1));
          @include prefix(border-radius, 5px);
          @include prefix(transition, all .3s ease);

          h2 {
            padding: 15px;
            background: $timeline-color;
            color: #fff;
            margin: -20px -20px 0 -20px;
            font-weight: 300;
            @include prefix(border-radius, 3px 3px 0 0);
          }

          &:before {
            content: '';
            position: absolute;
            left: 45%;
            top: 20px;
            width: 0;
            height: 0;
            border-top: 7px solid transparent;
            border-bottom: 7px solid transparent;
            border-left:7px solid $timeline-color;
          }

          &.right {
            float: right;

            &:before {
              content: '';
              right: 45%;
              left: inherit;
              border-left: 0;
              border-right: 7px solid $timeline-color;
            }
          }
        }
      }
    }


    @media screen and (max-width: 768px) {
      #timeline {
        margin: 30px;
        padding: 0px;
        width: 90%;
        &:before {
          left: 0;
        }

        .timeline-item {
          .timeline-content {
            width: 90%;
            float: right;

            &:before, &.right:before {
              left: 10%;
              margin-left: -6px;
              border-left: 0;
              border-right: 7px solid $timeline-color;
            }
          }

          .timeline-icon {
            left: 0;
          }
        }
      }
    }
  }

  #urge {
    background: $dark-blue;
    color: #fff;
    padding: 100px 0;
    p{
      font-size: 31px;
    }

    #bar-chart-img {
      width: 25%;
      position: relative;
      bottom: 0;
      margin-right: 10px;
    }

    section > div { margin-top: 100px; }
    #urge-button {

    }
    #urge-image {
      image { margin-right: 15px; }
    }
  }
}

