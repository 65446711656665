.coupon-section {
  /*padding-top: 80px;*/
  padding-bottom: 80px;
  min-height: 600px;
}

.dateText {
  background: #fafafa;
  border-width: 1px 0px;
}

.coupon-date-text {
  color: #616161;
  font-size: 18px;
  padding: 0 5px;
}

.coupon-section {
  padding-top: 50px;
  padding-bottom: 50px;
}

.coupon-section {
  .coupon-date-pickers-section {
    margin-top: 20px;
    margin-bottom: 20px;
    .change-coupon-day-section,
    .change-coupon-date-section{

      overflow: auto;
    }

    @media (max-width: 992px) {
      .change-coupon-date-section {
        margin-top: 30px;
      }
    }
  }



}
.change-coupon-day-section {
  .btn.buttonSquare {
      padding: 10px;
      min-width: auto !important;
     span {
       font-size: 20px;
     }
  }

}