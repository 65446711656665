// Vue CSS
[v-cloak] {
  display: none;
}

.overflow-x-overlay {
  overflow-x: overlay;
}

.grey-bg {
  background: #f1f2f3;
}

.bordered {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}
