.contact-intro {
  background-image: url('/img/map-bottom.png');
  padding: 50px 0;
  background-repeat: no-repeat;
}
.contact-intro {
  .alert-wrapper {
    min-height: 50px;
    margin-top: 10px;

    .alert.alert-success,
    .alert.alert-danger {
      background-color: transparent;
      margin-bottom: 0;
      border: none;
      padding: 0;
    }
  }
}
.contact-about {
  padding-top: 80px;
  padding-bottom: 80px;
  background: #ffffff;
}
.contact-details {
  margin-top: 50px;
}
.contact-link-with-us {
  margin-top: 30px;

  .social-section {
    margin-top: 20px;

    .social-title a {
      font-size: 21px;
    }


  }
  .social-image {
    margin-right: 5px;
  }
}
.send-message {
  .form-message {
    min-height: 50px;
  }
}


.spinner {
  display: inline-block;
  opacity: 0;
  width: 0;

  -webkit-transition: opacity 0.25s, width 0.25s;
  -moz-transition: opacity 0.25s, width 0.25s;
  -o-transition: opacity 0.25s, width 0.25s;
  transition: opacity 0.25s, width 0.25s;
}

.has-spinner.active {
  cursor:progress;
}

.has-spinner.active .spinner {
  opacity: 1;
  width: auto; /* This doesn't work, just fix for unkown width elements */
}

.has-spinner.btn-mini.active .spinner {
  width: 10px;
}

.has-spinner.btn-small.active .spinner {
  width: 13px;
}

.has-spinner.btn.active .spinner {
  width: 16px;
  margin-right: 10px;
}

.has-spinner.btn-large.active .spinner {
  width: 19px;
}
