// Results Table
#results_table_wrapper {
  margin-top: 30px;
  > div.row:first-child {
    div {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}
#results_table { border-top: 20px solid $brand-primary; }
#results_table > tbody .odds{background:#FFC }
#results_table > tbody .t12_fl{background:#e9f4e9}
#results_table > tbody .aa{background:#d1e8d1 }
#results_table > tbody .h12{background:#e9fea9 }
#results_table > tbody .st{background:#fff }
#results_table > tbody  .ss{background:#f2f2f2 }

#results_table.table-bordered {
  border-color: $brand-primary;
}


.stats-table {
  > thead > tr > th {
    text-align: center;
  }

  > tbody {
    color: #444;
  }
  .possible-stat {
    background: $green;
    color: $white;
  }
}
.stats-table.table-bordered {
  > thead > tr > th,
  > thead > tr > td,
  > tbody > tr > th,
  > tbody > tr > td,
  > tfoot > tr > th,
  > tfoot > tr > td  {
    border-color: $brand-primary;
  }
  background: $white;
  border-color: $brand-primary;
  color: $brand-primary;
  margin-top: 50px;
  td.market-name {
    font-weight: bold;
  }
}

.stats-panel-area {
  margin-top: 50px;
}
.stats-panel {
  .panel-body {
    background: $white-dark;
  }
  .panel-heading button,
  .panel-heading button:hover {
    border: transparent;
  }
  .panel-title {
    line-height: 1.5;
  }
}


.col-match-elements .time-picker > input.display-time {
  @extend .form-control;
  background-color: #ffffff !important;
}
.col-match-elements .time-picker > input[readonly] {
  background-color: #ffffff;
  text-align: center;
}


.dashed-line {
  border:none;
  border-top:3px dashed #f2f3f4;
  color:#fff;
  background-color:#fff;
  height:1px;
  margin-top: 70px;
  margin-bottom: 45px;
}

.solid-line {
  border:none;
  border-top:5px solid #f2f3f4;
  color:#fff;
  background-color:#fff;
  height:1px;
  margin-top: 70px;
  margin-bottom: 45px;
}