.color-blue {
  color: $blue;
}

.color-green {
  color: $green;
}

.color-red {
  color: $red;
}

.color-silver {
  color: $silver;
}

.color-olive {
  color: $olive;
}

.color-orange {
  color: $orange;
}

.color-maroon {
  color: $maroon;
}

.color-yellow {
  color: $yellow;
}

.color-aqua {
  color: $aqua;
}

.color-teal {
  color: $teal;
}

.color-gray-elegant {
  color: $gray-elegant;
}


.color-facebook {
  color: rgb( 59, 89, 152);
}
.background-color-blue {
  background-color: #51A9D6;
}

.background-color-green {
  background-color: #52AD81;
}

.background-color-red {
  background-color: #CD5A48;
}

.background-color-grey {
  background-color: #d1d2d3;
}